'use client'
import {Checkbox, type CheckboxProps} from '~/design-system/foundations'
import HgIcon from '../HgIcon'
import {tv} from 'tailwind-variants'
import {useId} from 'react'

const hgCheckboxVariants = tv({
  slots: {
    checkbox:
      'checkbox peer h-20 w-20 shrink-0 rounded border border-border-input bg-surface-input focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-border-focus disabled:bg-surface-input-disabled data-[state=checked]:bg-surface-primary [&[data-state=checked]:disabled]:bg-surface-primary-disabled',
    label:
      'cursor-pointer pl-8 text-text-subdued arcadia-ui-1 hover:text-text-default peer-hover:text-text-default peer-disabled:cursor-default peer-disabled:text-text-disabled',
  },
  variants: {
    focused: {
      true: {
        checkbox: 'outline outline-2 outline-offset-2 outline-border-focus',
      },
    },
    hovered: {
      true: {
        checkbox: 'bg-surface-input-hover',
        label: 'text-text-default',
      },
    },
  },
})

export type HgCheckboxProps = {
  label: string
  checked?: boolean
  focused?: boolean
  hovered?: boolean
} & Omit<CheckboxProps, 'indicator' | 'id'>

export default function HgCheckbox({label, ...props}: HgCheckboxProps) {
  const id = useId()
  const {label: labelClass, checkbox} = hgCheckboxVariants({
    focused: props.focused,
    hovered: props.hovered,
  })
  return (
    <div className="flex items-center [&:hover_.checkbox:not([data-state=checked]):not(:disabled)]:bg-surface-input-hover [&:hover_.checkbox[data-state=checked]:not(:disabled)]:bg-surface-primary-hover">
      <Checkbox
        className={checkbox()}
        id={id}
        indicator={
          <HgIcon
            iconType="checkmark"
            size="small"
            className="text-icon-on-primary"
          />
        }
        {...props}
      />
      <label htmlFor={id} className={labelClass()}>
        {label}
      </label>
    </div>
  )
}
